
import { FlowStep } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'
import { LandscapeModule } from '@/modules/landscape/store'
import ObjectFamilyMenu from '@/modules/model/components/objects/object-family-menu.vue'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import stepTypes from '../../helpers/step-types'

@Component({
  components: {
    ObjectFamilyMenu
  },
  name: 'FlowStepPreview'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly step?: FlowStep

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get origin () {
    return this.step?.originId ? this.currentDiagram?.objects[this.step.originId] : undefined
  }

  get originModel () {
    return this.origin ? this.modelModule.objects[this.origin.modelId] : undefined
  }

  get type () {
    const stepType = this.step?.type
    return stepType ? stepTypes.find(o => o.id === stepType) : undefined
  }

  get target () {
    return this.step?.targetId ? this.currentDiagram?.objects[this.step.targetId] : undefined
  }

  get targetModel () {
    return this.target ? this.modelModule.objects[this.target.modelId] : undefined
  }
}
