
import { Flow } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'
import { ModelModule } from '@/modules/model/store'
import { RouteModule } from '@/modules/route/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

@Component({
  name: 'FlowSubflowBack'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  routeModule = getModule(RouteModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly flow!: Flow

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  back () {
    const lastRouteForFlow = this.routeModule.history.find(o => o.query?.flow === this.flow.handleId)
    if (lastRouteForFlow?.name) {
      this.$router.push({
        name: lastRouteForFlow.name,
        params: lastRouteForFlow.params,
        query: this.$setQuery({
          ...lastRouteForFlow.query,
          flow_parent: this.$removeQueryArray(this.flow.handleId)
        })
      })
    } else {
      const diagram = this.flow ? this.diagramModule.diagrams[this.flow.diagramId] : undefined
      const diagramModel = diagram ? this.modelModule.objects[diagram.modelId] : undefined
      if (this.flow && diagram && diagramModel) {
        this.$router.push({
          name: this.currentShareLink ? 'share-diagram' : this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
          params: this.$params,
          query: this.$setQuery({
            comment: undefined,
            connection: undefined,
            diagram: diagram.handleId,
            flow: this.flow.handleId,
            flow_parent: this.$removeQueryArray(this.flow.handleId),
            flow_path: undefined,
            flow_step: undefined,
            model: diagramModel.handleId,
            object: undefined,
            x1: undefined,
            x2: undefined,
            y1: undefined,
            y2: undefined
          })
        })
      }
    }
  }
}
