
import { DiagramConnection, FlowStep, FlowStepPartial, FlowStepType, PermissionType, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import randomId from '@/helpers/random-id'
import * as sort from '@/helpers/sort'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as flowConstraint from '../../helpers/constraint'
import FlowPath from '../path/index.vue'
import SubflowEdit from '../subflow/edit.vue'
import Subflow from '../subflow/index.vue'
import StepAddMenu from './add-menu.vue'
import StepEdit from './edit.vue'
import Step from './index.vue'
import StepListFooter from './list-footer.vue'

type Item = {
  id: string
  index: number | null
  pathId: string | null
  steps: FlowStep[]
  type: FlowStepType | 'footer' | 'path-empty' | 'path-end' | null
  visibleIndex: number | null
}

@Component({
  components: {
    FlowPath,
    Step,
    StepAddMenu,
    StepEdit,
    StepListFooter,
    Subflow,
    SubflowEdit
  },
  name: 'FlowStepList'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly permission!: PermissionType

  @Ref() readonly containerRef!: HTMLElement
  @Ref() readonly stepContainerRefs!: HTMLElement[]
  @Ref() readonly stepEditRefs!: StepEdit[]
  @Ref() readonly flowPathRefs!: FlowPath[]

  editingIndexValue = ''
  editingIndex = -1
  hoverIndex = -1
  hoverAddIndex = -1
  menuAddIndex = -1
  menuActionIndex = -1

  keydownListener?: (event: KeyboardEvent) => void

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentFlowHandleId () {
    return this.$queryValue('flow')
  }

  get currentFlowStepId () {
    return this.$queryValue('flow_step')
  }

  get currentFlowPathIds () {
    return this.$queryArray('flow_path')
  }

  get currentObjectIds () {
    return this.$queryArray('object')
  }

  get currentConnectionIds () {
    return this.$queryArray('connection')
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentFlow () {
    return Object.values(this.flowModule.flows).find(o => o.diagramId === this.currentDiagram.id && o.handleId === this.currentFlowHandleId)
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)!
  }

  get currentFlowStep () {
    return this.currentFlowStepId ? this.currentFlow?.steps[this.currentFlowStepId] : undefined
  }

  get currentFlowSteps () {
    return this.currentFlow?.steps || {}
  }

  get currentFlowStepsPaths () {
    return Object
      .values(this.currentFlowSteps)
      .filter(o => !o.pathId)
      .reduce<FlowStep[][]>((p, c) => {
        const existingGroup = p.find(o => c.type?.endsWith('-path') && o[0].type?.endsWith('-path') && c.index === o[0].index)
        if (existingGroup) {
          existingGroup.push(c)
          return p
        } else {
          return [...p, [c]]
        }
      }, [])
      .sort((a, b) => sort.index(a[0], b[0]))
      .map(o => o.sort(sort.pathIndex))
  }

  get currentFlowStepsPath () {
    return this.currentFlowStepsPaths.map(o => {
      return o.find(p => p.type?.endsWith('-path') && this.currentFlowPathIds.includes(p.id)) || o[0]
    })
  }

  get currentFlowStepsPathSteps () {
    return this.currentFlowStepsPath.map(o => {
      if (o.type?.endsWith('-path')) {
        return Object
          .values(this.currentFlowSteps)
          .filter(s => o.id === s.pathId)
          .sort(sort.pathIndex)
      } else {
        return [o]
      }
    })
  }

  get items () {
    let nextVisibleIndex = 0

    const items: Item[] = []
    this.currentFlowStepsPath.forEach((o, index) => {
      if (o.type?.endsWith('-path')) {
        items.push({
          id: `${index}`,
          index,
          pathId: null,
          steps: this.currentFlowStepsPaths[index],
          type: o.type,
          visibleIndex: null
        })

        items.push(...this.currentFlowStepsPathSteps[index].map((s, i): Item => ({
          id: s.id,
          index,
          pathId: o.id,
          steps: [s],
          type: s.type,
          visibleIndex: nextVisibleIndex + i
        })))

        nextVisibleIndex += this.currentFlowStepsPathSteps[index].length

        if (!this.currentFlowStepsPathSteps[index].length && this.permission !== 'read') {
          items.push({
            id: `${o.id}-empty`,
            index,
            pathId: o.id,
            steps: [],
            type: 'path-empty',
            visibleIndex: null
          })
        }

        items.push({
          id: `${o.id}-end`,
          index,
          pathId: o.id,
          steps: [],
          type: 'path-end',
          visibleIndex: null
        })
      } else {
        items.push({
          id: o.id,
          index,
          pathId: null,
          steps: [o],
          type: o.type,
          visibleIndex: nextVisibleIndex
        })
        nextVisibleIndex++
      }
    })

    if (this.permission !== 'read') {
      items.push({
        id: 'footer',
        index: null,
        pathId: null,
        steps: [],
        type: 'footer',
        visibleIndex: null
      })
    }

    return items
  }

  get currentItem () {
    const item = this.items.find(o => o.id === this.currentFlowStepId)
    const index = this.items.findIndex(o => o.id === this.currentFlowStepId)
    if (item && index > -1) {
      return {
        id: item.id,
        index
      }
    }
  }

  get currentPathsShowing () {
    const item = this.items.find(o => o.id === this.currentFlowStepId)
    if (item?.pathId) {
      if (item.steps[0].index !== null && item.steps[0].pathIndex === 0 && this.currentFlow?.steps[item.pathId].type === 'parallel-path') {
        return this.currentFlowStepsPaths[item.steps[0].index].length
      } else {
        return 1
      }
    } else {
      return 0
    }
  }

  get flowStepIdCount () {
    return Object.values(this.currentFlowSteps).reduce((p, c) => {
      if (c.originId) {
        if (!p[c.originId]) {
          p[c.originId] = 0
        }
        p[c.originId] += 2
      }
      if (c.targetId) {
        if (!p[c.targetId]) {
          p[c.targetId] = 0
        }
        p[c.targetId]++
      }
      return p
    }, {} as Record<string, number>)
  }

  get objects () {
    const flowStepIdCount = this.flowStepIdCount
    return Object
      .values(this.currentDiagram.objects || {})
      .map(o => ({
        ...o,
        model: this.modelModule.objects[o.modelId]
      }))
      .filter(o => o.model)
      .sort((a, b) => {
        const aCount = flowStepIdCount[b.id] || 0
        const bCount = flowStepIdCount[a.id] || 0
        if (aCount === bCount) {
          return a.model.name.localeCompare(b.model.name)
        } else {
          return aCount - bCount
        }
      })
  }

  @Watch('currentItem')
  async onCurrentItemIndexChanged (currentItem?: { id: string, index: number }, prevCurrentItemIndex?: { id: string, index: number }) {
    if (currentItem !== undefined && (currentItem.id !== prevCurrentItemIndex?.id || currentItem.index !== prevCurrentItemIndex?.index)) {
      await this.$nextTick()
      this.scrollToStep(currentItem.id)
    }
  }

  async mounted () {
    this.keydownListener = this.onKeydown.bind(this)
    window.addEventListener('keydown', this.keydownListener)

    await this.$nextTick()

    if (this.currentItem) {
      this.scrollToStep(this.currentItem.id, true)
    }
  }

  destroyed () {
    if (this.keydownListener) {
      window.removeEventListener('keydown', this.keydownListener)
    }
  }

  onKeydown (event: KeyboardEvent) {
    const metaKey = window.navigator.platform.toLowerCase().includes('mac') ? event.metaKey : event.ctrlKey

    if (this.currentFlow && this.editingIndex > -1) {
      const key = parseInt(event.key)
      const toIndex = parseInt(this.editingIndexValue) - 1

      if (key > -1 && this.editingIndexValue.length < 2) {
        this.editingIndexValue += key
      } else if (event.key === 'Backspace') {
        this.editingIndexValue = this.editingIndexValue.slice(0, -1)
      } else if (event.key === 'Escape') {
        this.editingIndex = -1
      } else if (this.currentFlowStepId && toIndex !== undefined && !isNaN(toIndex) && event.key === 'Enter') {
        this.updateStepVisibleIndex(this.currentFlowStepId, toIndex)
        this.editingIndex = -1
      } else if (event.key === 'Enter') {
        this.editingIndex = -1
      }
    } else if (event.key.toLowerCase() === 'e' && event.shiftKey && metaKey && this.permission !== 'read' && this.currentObjectIds.length) {
      this.addInferredStep()
      event.preventDefault()
    }
  }

  scrollToStep (id: string, scrollStepToTop = false) {
    const stepElement = this.stepContainerRefs?.find(o => o.id === id)
    if (stepElement) {
      const stepY = stepElement.offsetTop
      if (scrollStepToTop || stepY < this.containerRef.scrollTop) {
        this.containerRef.scrollTop = stepY
      } else if (id === this.items[this.items.length - 2].id) {
        this.containerRef.scrollTop = this.containerRef.scrollHeight - this.containerRef.clientHeight
      } else if (stepY + stepElement.clientHeight > this.containerRef.scrollTop + this.containerRef.clientHeight) {
        this.containerRef.scrollTop = (stepY + stepElement.clientHeight) - this.containerRef.clientHeight
      }
    }
  }

  async addPath (type: 'alternate-path' | 'parallel-path', index?: number, append = false) {
    let stepIndex: number
    if (index !== undefined) {
      stepIndex = index
    } else {
      stepIndex = Math.max(-1, ...Object.values(this.currentFlow?.steps || {}).map(o => o.index)) + 1
    }

    let pathIndex: number | null
    if (append) {
      const pathIndexes = this.currentFlowStepsPaths[stepIndex]
        .filter(o => o.type?.endsWith('-path'))
        .map(o => o.pathIndex)
        .filter((o): o is number => o !== undefined)

      pathIndex = Math.max(-1, ...pathIndexes) + 1
    } else {
      pathIndex = null
    }

    const id = this.addStep({
      description: 'Path',
      flowId: null,
      index: stepIndex,
      originId: null,
      pathId: null,
      pathIndex,
      targetId: null,
      type,
      viaId: null
    })

    await this.$replaceQuery({
      flow_path: {
        ...this.$unionQueryArray(id),
        ...this.$removeQueryArray(...this.currentFlowStepsPaths[stepIndex]?.map(o => o.id).filter(o => o !== id) || [])
      }
    })

    this.scrollToStep(`${id}-end`)

    const pathListRef = this.flowPathRefs
      .map(o => o.pathListRef)
      .flat()
      .filter(o => o)
      .find(o => o.steps.some(s => s.id === id))

    if (pathListRef) {
      pathListRef.editingStepId = id
    }
  }

  async addInferredStep (props?: Partial<Pick<FlowStep, 'index' | 'pathId' | 'pathIndex'>>) {
    const objects = Object.values(this.currentDiagram.objects).filter(o => this.currentObjectIds.includes(o.id))
    const connections = Object.values(this.currentDiagram.connections).filter(o => this.currentConnectionIds.includes(o.id))
    const connection: DiagramConnection | undefined = connections[0]
    const connectionModel = connection?.modelId ? this.modelModule.connections[connection.modelId] : undefined

    const step: Pick<FlowStep, 'description' | 'pathId' | 'pathIndex' | 'flowId' | 'index' | 'type' | 'originId' | 'targetId' | 'viaId'> = {
      description: connectionModel?.name || '',
      flowId: null,
      index: 0,
      originId: objects[0]?.id || connection?.originId || null,
      pathId: props?.pathId || null,
      pathIndex: null,
      targetId: null,
      type: null,
      viaId: null
    }

    if (props?.index !== undefined) {
      step.index = props.index
    } else {
      step.index = Math.max(-1, ...Object.values(this.currentFlow?.steps || {}).map(o => o.index)) + 1
    }

    if (step.pathId) {
      if (props?.pathIndex !== undefined) {
        step.pathIndex = props.pathIndex
      } else {
        step.pathIndex = Math.max(-1, ...Object.values(this.currentFlow?.steps || {}).filter(o => o.pathId === step.pathId).map(o => o.index)) + 1
      }
    }

    const types = flowConstraint.type(step, this.currentDiagram.connections, this.modelModule.connections)
    if (types instanceof Array && types.includes('outgoing')) {
      step.type = 'outgoing'
    }

    if (objects.length <= 1 && connections.length <= 1) {
      const filteredConnections: Record<string, DiagramConnection> = connections.length ? { [connections[0].id]: connections[0] } : this.currentDiagram.connections || {}

      const types = flowConstraint.type(step, filteredConnections, this.modelModule.connections)
      if (types instanceof Array && types.includes('outgoing')) {
        if (!step.type) {
          step.type = 'outgoing'
        }

        const targets = flowConstraint.target(step, filteredConnections, this.modelModule.connections)
        if (targets instanceof Array && targets.length === 1) {
          if (!step.targetId) {
            step.targetId = targets[0]
          }

          const connections = flowConstraint.connection(step, filteredConnections, this.modelModule.connections)
          if (connections instanceof Array && connections.length === 1) {
            if (!step.viaId) {
              step.viaId = connections[0]
            }
          }
        }
      }
    }

    if (objects.length === 1) {
      const types = flowConstraint.type(step, this.currentDiagram.connections || {}, this.modelModule.connections)
      if (types instanceof Array && types.includes('outgoing')) {
        if (!step.type) {
          step.type = 'outgoing'
        }

        const targets = flowConstraint.target(step, this.currentDiagram.connections || {}, this.modelModule.connections)
        if (targets instanceof Array && targets.length === 1) {
          if (!step.targetId) {
            step.targetId = targets[0]
          }

          const connections = flowConstraint.connection(step, this.currentDiagram.connections || {}, this.modelModule.connections)
          if (connections instanceof Array && !step.viaId && connections.length === 1) {
            if (!step.viaId) {
              step.viaId = connections[0]
            }
          }
        }
      }
    }

    const id = this.addStep(step)
    await this.$replaceQuery({
      flow_step: id
    })
  }

  async addSubflow (props?: Partial<Pick<FlowStep, 'index' | 'pathId' | 'pathIndex'>>) {
    const step: Pick<FlowStep, 'description' | 'pathId' | 'pathIndex' | 'flowId' | 'index' | 'type' | 'originId' | 'targetId' | 'viaId'> = {
      description: '',
      flowId: null,
      index: 0,
      originId: null,
      pathId: props?.pathId || null,
      pathIndex: null,
      targetId: null,
      type: 'subflow',
      viaId: null
    }

    if (props?.index !== undefined) {
      step.index = props.index
    } else {
      step.index = Math.max(-1, ...Object.values(this.currentFlow?.steps || {}).map(o => o.index)) + 1
    }

    if (step.pathId) {
      if (props?.pathIndex !== undefined) {
        step.pathIndex = props.pathIndex
      } else {
        step.pathIndex = Math.max(-1, ...Object.values(this.currentFlow?.steps || {}).filter(o => o.pathId === step.pathId).map(o => o.index)) + 1
      }
    }

    const id = this.addStep(step)
    await this.$replaceQuery({
      flow_step: id
    })
  }

  async duplicateStep (props: Pick<FlowStep, 'description' | 'flowId' | 'pathId' | 'pathIndex' | 'type' | 'originId' | 'targetId' | 'viaId'>, index: number) {
    const id = this.addStep({
      ...props,
      index
    })
    await this.$replaceQuery({
      flow_step: id
    })
  }

  addStep (props: Pick<FlowStep, 'description' | 'flowId' | 'pathId' | 'pathIndex' | 'index' | 'type' | 'originId' | 'targetId' | 'viaId'>) {
    if (!this.currentFlow) {
      throw new Error('Flow not found')
    }

    const id = randomId()
    const step: FlowStep = {
      ...props,
      id
    }

    const revertTasks: Task[] = [{
      id: this.currentFlow.id,
      props: {
        steps: {
          $remove: [id]
        }
      },
      type: 'flow-update'
    }, {
      route: this.$route,
      type: 'navigation'
    }]

    const { flow, flowUpdate } = this.flowModule.generateFlowCommit(this.currentFlow.id, {
      steps: {
        $add: {
          [id]: step
        }
      }
    })
    this.flowModule.setFlowVersion(flow)
    this.editorModule.addToTaskQueue({
      func: () => this.flowModule.flowUpdate({
        flowId: flow.id,
        landscapeId: this.currentLandscape.id,
        props: flowUpdate,
        versionId: this.currentVersion.id
      })
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [{
        id: flow.id,
        props: flowUpdate,
        type: 'flow-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })

    this.editingIndex = -1

    return id
  }

  async updateStepIndex (fromIndex: number, toIndex: number) {
    if (!this.currentFlow) {
      return
    }

    const oldSteps = [...this.currentFlowStepsPaths]
    const [element] = oldSteps.splice(fromIndex, 1)
    oldSteps.splice(Math.max(0, toIndex), 0, element)

    const prevSteps = oldSteps.reduce<Record<string, FlowStepPartial>>((p, c, i) => ({
      ...p,
      ...c.reduce<Record<string, FlowStepPartial>>((pp, cc) => {
        if (cc.index === i) {
          return pp
        } else {
          return {
            ...pp,
            [cc.id]: {
              index: cc.index
            }
          }
        }
      }, {})
    }), {})

    const steps = oldSteps.reduce<Record<string, FlowStepPartial>>((p, c, i) => ({
      ...p,
      ...c.reduce<Record<string, FlowStepPartial>>((pp, cc) => {
        if (cc.index === i) {
          return pp
        } else {
          return {
            ...pp,
            [cc.id]: {
              index: i
            }
          }
        }
      }, {})
    }), {})

    const revertTasks: Task[] = [{
      id: this.currentFlow.id,
      props: {
        steps: {
          $update: prevSteps
        }
      },
      type: 'flow-update'
    }, {
      route: this.$route,
      type: 'navigation'
    }]

    const { flow, flowUpdate } = this.flowModule.generateFlowCommit(this.currentFlow.id, {
      steps: {
        $update: steps
      }
    })
    this.flowModule.setFlowVersion(flow)
    this.editorModule.addToTaskQueue({
      func: () => this.flowModule.flowUpdate({
        flowId: flow.id,
        landscapeId: this.currentLandscape.id,
        props: flowUpdate,
        versionId: this.currentVersion.id
      })
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [{
        id: flow.id,
        props: flowUpdate,
        type: 'flow-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })

    this.editingIndex = -1
  }

  async updatePathIndex (index: number, fromIndex: number, toIndex: number) {
    if (!this.currentFlow) {
      return
    }

    const oldSteps = this.currentFlowStepsPaths[index]
    const [element] = oldSteps.splice(fromIndex, 1)
    oldSteps.splice(Math.max(0, toIndex), 0, element)

    const prevSteps = oldSteps.reduce<Record<string, FlowStepPartial>>((p, c, i) => {
      if (c.pathIndex === i) {
        return p
      } else {
        return {
          ...p,
          [c.id]: {
            pathIndex: c.index
          }
        }
      }
    }, {})

    const steps = oldSteps.reduce<Record<string, FlowStepPartial>>((p, c, i) => {
      if (c.pathIndex === i) {
        return p
      } else {
        return {
          ...p,
          [c.id]: {
            pathIndex: i
          }
        }
      }
    }, {})

    const revertTasks: Task[] = [{
      id: this.currentFlow.id,
      props: {
        steps: {
          $update: prevSteps
        }
      },
      type: 'flow-update'
    }, {
      route: this.$route,
      type: 'navigation'
    }]

    const { flow, flowUpdate } = this.flowModule.generateFlowCommit(this.currentFlow.id, {
      steps: {
        $update: steps
      }
    })
    this.flowModule.setFlowVersion(flow)
    this.editorModule.addToTaskQueue({
      func: () => this.flowModule.flowUpdate({
        flowId: flow.id,
        landscapeId: this.currentLandscape.id,
        props: flowUpdate,
        versionId: this.currentVersion.id
      })
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [{
        id: flow.id,
        props: flowUpdate,
        type: 'flow-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })

    this.editingIndex = -1
  }

  async updateStepVisibleIndex (id: string, to: number | 'up' | 'down') {
    if (!this.currentFlow) {
      return
    }

    const fromStep = this.currentFlowSteps[id]

    let newSteps = window.structuredClone(this.currentFlowSteps)

    // move up and down
    if (to === 'up' || to === 'down') {
      if (
        // move step up out of path
        to === 'up' &&
        fromStep.pathIndex === 0
      ) {
        newSteps[id].index = fromStep.index - 0.5
        newSteps[id].pathId = null
        newSteps[id].pathIndex = null
      } else if (
        // move step down out of path
        to === 'down' &&
        fromStep.pathIndex === this.currentFlowStepsPathSteps[fromStep.index].length - 1
      ) {
        newSteps[id].index = fromStep.index + 0.5
        newSteps[id].pathId = null
        newSteps[id].pathIndex = null
      } else if (
        // move step up into path
        to === 'up' &&
        fromStep.index > 0 &&
        this.currentFlowStepsPath[fromStep.index - 1].type?.endsWith('-path')
      ) {
        newSteps[id].index = this.currentFlowStepsPath[fromStep.index - 1].index
        newSteps[id].pathId = this.currentFlowStepsPath[fromStep.index - 1].id
        newSteps[id].pathIndex = this.currentFlowStepsPathSteps[fromStep.index - 1].length
      } else if (
        // move step down into path
        to === 'down' &&
        fromStep.index < this.currentFlowStepsPath.length - 1 &&
        this.currentFlowStepsPath[fromStep.index + 1].type?.endsWith('-path')
      ) {
        newSteps[id].index = this.currentFlowStepsPath[fromStep.index + 1].index
        newSteps[id].pathId = this.currentFlowStepsPath[fromStep.index + 1].id
        newSteps[id].pathIndex = -0.5
      } else if (
        // move step up within path
        to === 'up' &&
        fromStep.pathId &&
        fromStep.pathIndex !== null &&
        fromStep.pathIndex > 0
      ) {
        newSteps[id].pathIndex = fromStep.pathIndex - 1.5
      } else if (
        // move step down within path
        to === 'down' &&
        fromStep.pathId &&
        fromStep.pathIndex !== null &&
        fromStep.pathIndex < this.currentFlowStepsPathSteps[fromStep.index].length - 1
      ) {
        newSteps[id].pathIndex = fromStep.pathIndex + 1.5
      } else if (
        // move step up outside path
        to === 'up' &&
        !fromStep.pathId &&
        fromStep.index > 0
      ) {
        newSteps[id].index = fromStep.index - 1.5
      } else if (
        // move step down outside path
        to === 'down' &&
        !fromStep.pathId &&
        fromStep.index < this.currentFlowStepsPath.length - 1
      ) {
        newSteps[id].index = fromStep.index + 1.5
      }
    } else {
      // move by index
      const orderedSteps = this.currentFlowStepsPathSteps.flat()

      const toIndexCapped = Math.max(0, Math.min(orderedSteps.length - 1, to))
      const toStep = orderedSteps[toIndexCapped]

      if (
        // move step within a path
        fromStep.pathId &&
        toStep.pathId &&
        fromStep.pathId === toStep.pathId &&
        toStep.pathIndex !== null &&
        fromStep.pathIndex !== null
      ) {
        if (toStep.pathIndex > fromStep.pathIndex) {
          newSteps[id].pathIndex = toStep.pathIndex + 0.5
        } else {
          newSteps[id].pathIndex = toStep.pathIndex - 0.5
        }
      } else if (
        // move a step into path
        !fromStep.pathId &&
        toStep.pathId &&
        toStep.pathIndex !== null
      ) {
        newSteps[id].pathId = toStep.pathId

        if (toStep.index > fromStep.index) {
          newSteps[id].index = toStep.index + 0.5
        } else {
          newSteps[id].index = toStep.index - 0.5
        }

        if (toStep.index > fromStep.index) {
          newSteps[id].pathIndex = toStep.pathIndex + 0.5
        } else {
          newSteps[id].pathIndex = toStep.pathIndex - 0.5
        }
      } else if (
        // move a step out of path
        fromStep.pathId &&
        !toStep.pathId
      ) {
        if (toStep.index > fromStep.index) {
          newSteps[id].index = toStep.index + 0.5
        } else {
          newSteps[id].index = toStep.index - 0.5
        }

        newSteps[id].pathId = null
        newSteps[id].pathIndex = null
      } else if (
        // move a step outside of path
        !fromStep.pathId &&
        !toStep.pathId
      ) {
        if (toStep.index > fromStep.index) {
          newSteps[id].index = toStep.index + 0.5
        } else {
          newSteps[id].index = toStep.index - 0.5
        }
      }
    }

    newSteps = sort.flowSteps(newSteps)

    const prevSteps = Object.values(newSteps).reduce<Record<string, FlowStepPartial>>((p, c) => {
      const props: FlowStepPartial = {}
      if (c.index !== this.currentFlowSteps[c.id].index) {
        props.index = this.currentFlowSteps[c.id].index
      }
      if (c.pathId !== this.currentFlowSteps[c.id].pathId) {
        props.pathId = this.currentFlowSteps[c.id].pathId
      }
      if (c.pathIndex !== this.currentFlowSteps[c.id].pathIndex) {
        props.pathIndex = this.currentFlowSteps[c.id].pathIndex
      }
      if (Object.keys(props).length) {
        return {
          ...p,
          [c.id]: props
        }
      } else {
        return p
      }
    }, {})

    const steps = Object.values(newSteps).reduce<Record<string, FlowStepPartial>>((p, c) => {
      const props: FlowStepPartial = {}
      if (c.index !== this.currentFlowSteps[c.id].index) {
        props.index = c.index
      }
      if (c.pathId !== this.currentFlowSteps[c.id].pathId) {
        props.pathId = c.pathId
      }
      if (c.pathIndex !== this.currentFlowSteps[c.id].pathIndex) {
        props.pathIndex = c.pathIndex
      }
      if (Object.keys(props).length) {
        return {
          ...p,
          [c.id]: props
        }
      } else {
        return p
      }
    }, {})

    const revertTasks: Task[] = [{
      id: this.currentFlow.id,
      props: {
        steps: {
          $update: prevSteps
        }
      },
      type: 'flow-update'
    }, {
      route: this.$route,
      type: 'navigation'
    }]

    const { flow, flowUpdate } = this.flowModule.generateFlowCommit(this.currentFlow.id, {
      steps: {
        $update: steps
      }
    })
    this.flowModule.setFlowVersion(flow)
    this.editorModule.addToTaskQueue({
      func: () => this.flowModule.flowUpdate({
        flowId: flow.id,
        landscapeId: this.currentLandscape.id,
        props: flowUpdate,
        versionId: this.currentVersion.id
      })
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [{
        id: flow.id,
        props: flowUpdate,
        type: 'flow-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })

    this.editingIndex = -1
  }

  updatePathType (index: number, type: FlowStepType) {
    if (this.currentFlow) {
      const stepUpdate = Object
        .values(this.currentFlow.steps)
        .filter(o => o.index === index && o.type?.endsWith('-path'))
        .reduce<Record<string, FlowStepPartial>>((p, c) => ({
          ...p,
          [c.id]: {
            type
          }
        }), {})

      const stepRevert = Object
        .values(this.currentFlow.steps)
        .filter(o => o.index === index && o.type?.endsWith('-path'))
        .reduce<Record<string, FlowStepPartial>>((p, c) => ({
          ...p,
          [c.id]: {
            type: c.type
          }
        }), {})

      const revertTasks: Task[] = [{
        id: this.currentFlow.id,
        props: {
          steps: {
            $update: stepRevert
          }
        },
        type: 'flow-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]

      const { flow, flowUpdate } = this.flowModule.generateFlowCommit(this.currentFlow.id, {
        steps: {
          $update: stepUpdate
        }
      })
      this.flowModule.setFlowVersion(flow)
      this.editorModule.addToTaskQueue({
        func: () => this.flowModule.flowUpdate({
          flowId: flow.id,
          landscapeId: this.currentLandscape.id,
          props: flowUpdate,
          versionId: this.currentVersion.id
        })
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks: [{
          id: flow.id,
          props: flowUpdate,
          type: 'flow-update'
        }, {
          route: this.$route,
          type: 'navigation'
        }]
      })
    }
  }

  updateStep (id: string, prop: keyof FlowStep, value: any) {
    const currentFlowStep = this.currentFlowSteps[id]
    if (this.currentFlow && currentFlowStep) {
      const curStep = this.currentFlow.steps[currentFlowStep.id]
      const step = window.structuredClone(curStep)
      const stepUpdate: FlowStepPartial = {}

      if (prop === 'flowId' || prop === 'type' || prop === 'originId' || prop === 'targetId' || prop === 'viaId' || prop === 'description') {
        step[prop] = value
        stepUpdate[prop] = value
      }

      const types = flowConstraint.type(step, this.currentDiagram.connections || {}, this.modelModule.connections)
      if (prop !== 'type') {
        if (types instanceof Array && !types.some(o => o === curStep.type)) {
          step.type = null
          stepUpdate.type = null
        }
        if (!step.type && types instanceof Array) {
          if (types.length === 1 && types[0] === 'reply') {
            step.type = 'reply'
            stepUpdate.type = 'reply'
          } else if (types.includes('outgoing')) {
            step.type = 'outgoing'
            stepUpdate.type = 'outgoing'
          }
        }
      }

      const targets = flowConstraint.target(step, this.currentDiagram.connections || {}, this.modelModule.connections)
      if (prop !== 'targetId') {
        if (
          curStep.targetId &&
          ((targets instanceof Array && !targets.includes(curStep.targetId)) || !(targets instanceof Array) || step.type === 'self-action')
        ) {
          step.targetId = null
          stepUpdate.targetId = null
        }
        if (!step.targetId && targets instanceof Array && targets.length === 1) {
          step.targetId = targets[0]
          stepUpdate.targetId = targets[0]
        }
      }

      const connections = flowConstraint.connection(step, this.currentDiagram.connections || {}, this.modelModule.connections)
      if (prop !== 'viaId') {
        if (
          curStep.viaId &&
          ((connections instanceof Array && !connections.includes(curStep.viaId)) || !(connections instanceof Array) || step.type === 'self-action')
        ) {
          step.viaId = null
          stepUpdate.viaId = null
        }
        if (!step.viaId && connections instanceof Array && connections.length === 1) {
          step.viaId = connections[0]
          stepUpdate.viaId = connections[0]
        }
      }

      const revertTasks: Task[] = [{
        id: this.currentFlow.id,
        props: {
          steps: {
            $update: {
              [currentFlowStep.id]: this.currentFlow.steps[currentFlowStep.id]
            }
          }
        },
        type: 'flow-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]

      const { flow, flowUpdate } = this.flowModule.generateFlowCommit(this.currentFlow.id, {
        steps: {
          $update: {
            [currentFlowStep.id]: stepUpdate
          }
        }
      })
      this.flowModule.setFlowVersion(flow)
      this.editorModule.addToTaskQueue({
        func: () => this.flowModule.flowUpdate({
          flowId: flow.id,
          landscapeId: this.currentLandscape.id,
          props: flowUpdate,
          versionId: this.currentVersion.id
        })
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks: [{
          id: flow.id,
          props: flowUpdate,
          type: 'flow-update'
        }, {
          route: this.$route,
          type: 'navigation'
        }]
      })

      this.editingIndex = -1
    }
  }

  async duplicatePath (pathId: string) {
    if (!this.currentFlow) {
      return
    }

    const duplicatePath = Object.values(this.currentFlowSteps).find(o => o.id === pathId && o.type?.endsWith('-path'))
    if (!duplicatePath) {
      return
    }

    const pathIndexes = this.currentFlowStepsPaths[duplicatePath.index]
      .filter(o => o.type?.endsWith('-path'))
      .map(o => o.pathIndex)
      .filter((o): o is number => o !== undefined)

    const newPathIndex = Math.max(-1, ...pathIndexes) + 1
    const newPathId = randomId()

    const duplicateSteps = Object
      .values(this.currentFlowSteps)
      .filter(o => o.id === pathId || o.pathId === pathId)
      .reduce<Record<string, FlowStep>>((p, c) => {
        if (c.type?.endsWith('-path')) {
          return {
            ...p,
            [newPathId]: {
              ...c,
              description: `${c.description} copy`,
              id: newPathId,
              pathIndex: newPathIndex
            }
          }
        } else {
          const id = randomId()
          return {
            ...p,
            [id]: {
              ...c,
              id,
              pathId: newPathId
            }
          }
        }
      }, {})

    const revertTasks: Task[] = [{
      id: this.currentFlow.id,
      props: {
        steps: {
          $remove: Object.keys(duplicateSteps)
        }
      },
      type: 'flow-update'
    }, {
      route: this.$route,
      type: 'navigation'
    }]

    const { flow, flowUpdate } = this.flowModule.generateFlowCommit(this.currentFlow.id, {
      steps: {
        $add: duplicateSteps
      }
    })
    this.flowModule.setFlowVersion(flow)
    this.editorModule.addToTaskQueue({
      func: () => this.flowModule.flowUpdate({
        flowId: flow.id,
        landscapeId: this.currentLandscape.id,
        props: flowUpdate,
        versionId: this.currentVersion.id
      })
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [{
        id: flow.id,
        props: flowUpdate,
        type: 'flow-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })

    await this.$replaceQuery({
      flow_path: {
        ...this.$unionQueryArray(newPathId),
        ...this.$removeQueryArray(...this.currentFlowStepsPaths[duplicatePath.index]?.map(o => o.id).filter(o => o !== newPathId) || [])
      }
    })

    this.scrollToStep(`${newPathId}-end`)
  }

  async deleteStep (...ids: string[]) {
    const deleteSteps = Object.values(this.currentFlowSteps).filter(o => ids.includes(o.id) || (o.pathId && ids.includes(o.pathId)))
    const deleteStepIds = deleteSteps.map(o => o.id)
    if (!this.currentFlow || !deleteSteps.length) {
      return
    }

    const flowSteps = this.currentFlowStepsPathSteps.flat()
    const currentIndex = flowSteps.findIndex(o => o.id === this.currentFlowStepId)
    const firstStep = flowSteps.find(o => !deleteStepIds.includes(o.id))
    const previousStep = [...flowSteps].reverse().find((o, i) => flowSteps.length - i <= currentIndex && !deleteStepIds.includes(o.id)) || firstStep

    const query: any = {}
    if (this.currentFlowStepId && deleteStepIds.includes(this.currentFlowStepId) && previousStep) {
      query.flow_step = previousStep.id
    }
    if (this.currentFlowPathIds.length && deleteStepIds.some(o => this.currentFlowPathIds.includes(o))) {
      query.flow_path = this.$removeQueryArray(...deleteStepIds)
    }
    await this.$replaceQuery(query)

    const revertTasks: Task[] = [{
      id: this.currentFlow.id,
      props: {
        steps: {
          $add: deleteSteps.reduce<Record<string, FlowStep>>((p, c) => ({
            ...p,
            [c.id]: c
          }), {})
        }
      },
      type: 'flow-update'
    }, {
      route: this.$route,
      type: 'navigation'
    }]

    const { flow, flowUpdate } = this.flowModule.generateFlowCommit(this.currentFlow.id, {
      steps: {
        $remove: deleteSteps.map(o => o.id)
      }
    })
    this.flowModule.setFlowVersion(flow)
    this.editorModule.addToTaskQueue({
      func: () => this.flowModule.flowUpdate({
        flowId: flow.id,
        landscapeId: this.currentLandscape.id,
        props: flowUpdate,
        versionId: this.currentVersion.id
      })
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [{
        id: flow.id,
        props: flowUpdate,
        type: 'flow-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })

    this.editingIndex = -1
  }
}
