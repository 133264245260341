
import { DiagramContent, DiagramObject, FlowStep, ModelConnection, ModelObject } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import * as flowConstraint from '../../helpers/constraint'
import StepPickerConnection from '../step-picker/connection.vue'
import StepPickerObject from '../step-picker/object.vue'
import StepPickerType from '../step-picker/type.vue'
import StepDescription from './description.vue'

@Component({
  components: {
    StepDescription,
    StepPickerConnection,
    StepPickerObject,
    StepPickerType
  },
  name: 'FlowStepEdit'
})
export default class extends Vue {
  @Ref() readonly descriptionRef!: StepDescription

  @Prop() readonly step!: FlowStep
  @Prop() readonly diagramContent!: DiagramContent
  @Prop() readonly objects!: (DiagramObject & { model: ModelObject })[]
  @Prop() readonly modelConnections!: Record<string, ModelConnection>

  get typeConstraint () {
    const types = flowConstraint.type(this.step, this.diagramContent.connections || {}, this.modelConnections)
    return types instanceof Array ? types : types.emptyReason
  }

  get targetConstraint () {
    const objectIds = flowConstraint.target(this.step, this.diagramContent.connections || {}, this.modelConnections)
    if (objectIds instanceof Array) {
      return this.objects
        .filter(o => objectIds.includes(o.id))
        .sort((a, b) => a.model.name.localeCompare(b.model.name))
    } else {
      return objectIds.emptyReason
    }
  }

  get connectionConstraint () {
    const connectionIds = flowConstraint.connection(this.step, this.diagramContent.connections || {}, this.modelConnections)
    if (connectionIds instanceof Array) {
      return Object
        .values(this.diagramContent.connections || {})
        .filter(o => o.modelId && connectionIds.includes(o.id))
        .map(o => ({
          ...o,
          model: this.modelConnections[o.modelId!]
        }))
        .filter(o => o.model)
        .sort((a, b) => a.model.name.localeCompare(b.model.name))
    } else {
      return connectionIds.emptyReason
    }
  }
}
