
import { FlowStep } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'
import { ModelModule } from '@/modules/model/store'

import stepTypes from '../../helpers/step-types'

@Component({
  name: 'FlowStep'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)

  @Prop() readonly step!: FlowStep
  @Prop() readonly active!: boolean

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get origin () {
    return this.step.originId && this.currentDiagram ? this.currentDiagram.objects[this.step.originId] : undefined
  }

  get originModel () {
    return this.origin ? this.modelModule.objects[this.origin.modelId] : undefined
  }

  get originModelType () {
    return this.originModel ? `${this.originModel.type.slice(0, 1).toUpperCase()}${this.originModel.type.slice(1)}` : undefined
  }

  get originText () {
    if (this.step.originId) {
      if (this.originModel) {
        return this.originModel.name || this.originModelType
      } else {
        return 'Removed'
      }
    } else {
      return 'No object'
    }
  }

  get originColor () {
    if (this.step.originId && this.originModel) {
      if (this.active) {
        return 'white--text'
      } else {
        return 'grey--text text--lighten-3'
      }
    } else {
      return 'grey--text text--lighten-5'
    }
  }

  get via () {
    return this.step.viaId && this.currentDiagram ? this.currentDiagram.connections[this.step.viaId] : undefined
  }

  get viaModel () {
    return this.via && this.via.modelId ? this.modelModule.connections[this.via.modelId] : undefined
  }

  get viaText () {
    if (this.step.viaId) {
      if (this.viaModel) {
        return this.viaModel.name
      } else {
        return 'Removed'
      }
    } else {
      return 'No connection'
    }
  }

  get viaColor () {
    if (this.step.viaId && this.viaModel) {
      if (this.active) {
        return 'white--text'
      } else {
        return 'grey--text text--lighten-3'
      }
    } else {
      return 'grey--text text--lighten-5'
    }
  }

  get type () {
    return stepTypes.find(o => o.id === this.step.type)
  }

  get target () {
    return this.step.targetId && this.currentDiagram ? this.currentDiagram.objects[this.step.targetId] : undefined
  }

  get targetModel () {
    return this.target ? this.modelModule.objects[this.target.modelId] : undefined
  }

  get targetModelType () {
    return this.targetModel ? `${this.targetModel.type.slice(0, 1).toUpperCase()}${this.targetModel.type.slice(1)}` : undefined
  }

  get targetText () {
    if (this.step.targetId) {
      if (this.targetModel) {
        return this.targetModel.name || this.targetModelType
      } else {
        return 'Removed'
      }
    } else {
      return 'No object'
    }
  }

  get targetColor () {
    if (this.step.targetId && this.targetModel) {
      if (this.active) {
        return 'white--text'
      } else {
        return 'grey--text text--lighten-3'
      }
    } else {
      return 'grey--text text--lighten-5'
    }
  }
}
