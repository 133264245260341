
import Vue from 'vue'
import Component from 'vue-class-component'

import StepAddMenu from './add-menu.vue'

@Component({
  components: {
    StepAddMenu
  },
  name: 'FlowStepListFooter'
})
export default class extends Vue {}
