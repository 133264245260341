
import { FlowStep, PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import * as sort from '@/helpers/sort'
import { DiagramModule } from '@/modules/diagram/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { FlowModule } from '../../store'
import StepPickerFlow from '../step-picker/flow.vue'

@Component({
  components: {
    StepPickerFlow
  },
  name: 'FlowSubflowEdit'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly permission!: PermissionType
  @Prop() readonly step!: FlowStep

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentFlowHandleId () {
    return this.$queryValue('flow')
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentFlow () {
    return Object.values(this.flowModule.flows).find(o => o.handleId === this.currentFlowHandleId)
  }

  get flows () {
    return Object.values(this.flowModule.flows).filter(o => o.handleId !== this.currentFlowHandleId)
  }

  get flow () {
    return this.step.flowId ? this.flowModule.flows[this.step.flowId] : null
  }

  get flowFirstStepDescription () {
    const firstStep = Object.values(this.flow?.steps || {}).sort(sort.index).find(o => o.index === 0 && o.description)
    return firstStep?.description ? `Step 1: ${firstStep.description}` : undefined
  }

  goToSubflow () {
    const flow = this.step.flowId ? this.flowModule.flows[this.step.flowId] : undefined
    const diagram = flow ? this.diagramModule.diagrams[flow.diagramId] : undefined
    const diagramModel = diagram ? this.modelModule.objects[diagram.modelId] : undefined
    if (flow && diagram && diagramModel) {
      this.$router.push({
        name: this.currentShareLink ? 'share-diagram' : this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
        params: this.$params,
        query: this.$setQuery({
          comment: undefined,
          connection: undefined,
          diagram: diagram.handleId,
          flow: flow.handleId,
          flow_parent: this.$unionQueryArray(this.currentFlow?.handleId || ''),
          flow_path: undefined,
          flow_step: undefined,
          model: diagramModel.handleId,
          object: undefined,
          x1: undefined,
          x2: undefined,
          y1: undefined,
          y2: undefined
        })
      })
    }
  }
}
